import React, { useState, useEffect } from 'react';

import * as Util from '../data/General';
import ChartRequest from '../components/widget/ChartRequest';
import Filter from '../components/widget/Filter';
import mixpanel from 'mixpanel-browser';
import DashboardCards from '../components/widget/DashboardCards';
import { useStatistics } from '../hooks/useStatistics';

function AnalyticsCampaigns(props) {
  const [isSearch, setIsSearch] = useState(false);
  const [listCampaings, setListCampaings] = useState(null);
  const [delivered, setDelivered] = useState(0);
  const [read, setRead] = useState(0);
  const [send, setSend] = useState(0);
  const [toRead, setToRead] = useState(0);
  const [error, setError] = useState(0);
  const [sales, setSales] = useState(0);
  const [salesAmount, setSalesAmount] = useState(0);
  const [rates, setRates] = useState(null);

  const [totalCampaings, setTotalCampaings] = useState(0);
  const [PendingCampaings, setPendingCampaings] = useState(0);
  const [roi, setRoi] = useState(0);
  const [costMenssage, setCostMenssage] = useState(0);
  const [total24, setTotal24] = useState(null);

  const isOKRates = (data) => {
    setRates(data);
    let shopify =  Util.getAdminShop();
    if (shopify && shopify.countryCode && data[shopify.countryCode]){
        let dat = data[shopify.countryCode];
        if(dat.rate && send > 0 ){
            let costMenssage = dat.mkt * dat.rate;
            costMenssage = send * costMenssage;
            setCostMenssage(costMenssage);
            setRoi((salesAmount/costMenssage).toFixed(2));
        }
    }
}


  let startDate = new Date();
  let endDate = new Date();
  startDate.setDate(endDate.getDate() - 360);
  
  const [filter, setFilter] = useState({
    fromNotifyDate: startDate.toLocaleDateString('en-CA', {year: 'numeric', month: '2-digit', day: '2-digit'}) + "T00:00:01",
    toNotifyDate: endDate.toLocaleDateString('en-CA', {year: 'numeric', month: '2-digit', day: '2-digit'})+ "T23:59:59"
  });
  const [url, setUrl] = useState("?fromNotifyDate=" +filter.fromNotifyDate + "&toNotifyDate="+filter.toNotifyDate);
  const [urlSpecial, setUrlSpecial] = useState("fromDate=" +filter.fromNotifyDate + "&toDate="+filter.toNotifyDate);
  
  let endDate24 = new Date();
  endDate24.setDate(endDate24.getDate() - 1);
  const [urlSpecial24, setUrlSpecial24] = useState("fromDate=" +endDate24.toLocaleDateString('en-CA', {year: 'numeric', month: '2-digit', day: '2-digit'})+ "T"+endDate24.toLocaleTimeString('it-IT') + "&toDate="+endDate.toLocaleDateString('en-CA', {year: 'numeric', month: '2-digit', day: '2-digit'})+ "T23:59:59");
  //const [urlSpecial24, setUrlSpecial24] = useState("fromDate=" +endDate24.toLocaleDateString('en-CA', {year: 'numeric', month: '2-digit', day: '2-digit'})+ "T"+"00:59:59" + "&toDate="+endDate.toLocaleDateString('en-CA', {year: 'numeric', month: '2-digit', day: '2-digit'})+ "T23:59:59");
  

  filter.from = 0;
  filter.state = "";
  filter.size = 10000;
  filter.sortBy="notifiedAt";
  filter.sortOrder="desc";
  filter.fromDate=filter.fromNotifyDate;
  filter.toDate=filter.toNotifyDate;

  const updateFilter = (fil) => {
    setFilter(fil);
    setIsSearch(false);
    setUrl("fromNotifyDate=" +fil.fromNotifyDate + "&toNotifyDate="+fil.toNotifyDate);
    setUrlSpecial("fromDate=" +fil.fromNotifyDate + "&toDate="+fil.toNotifyDate);
    refreshUrl("?fromDate=" +fil.fromNotifyDate + "&toDate="+fil.toNotifyDate);
  }

  
  const [status, setStatus] = useState(null);

  useEffect(() => {
    let shopJson =  Util.getAdminShop();
    if(shopJson){
      mixpanel.init(window.mixpanelKey); 
      mixpanel.track('NEW CAMPAINGS', {
          'shopDomain': shopJson.domain,
          'shopId': shopJson.id,
      });
      if(shopJson.marketingCredential && shopJson.marketingCredential.twilioUsername && shopJson.marketingCredential.twilioPassword && shopJson.marketingCredential.senderId){
        Util.getStatusWhatsapp(shopJson.marketingCredential, setStatus);
      }
    }
    Util.getStatistics(dataOk24, dataFail, "marketing/campaigns/statusstatistics?"+urlSpecial24);
    
  },[]);

  useEffect(() => {
    if(!isSearch){
      setIsSearch(true);
      Util.getCampaigns(filter, handleOk, dataFail);
      Util.getUtmStatistics(handleOkUTM, dataFail, `?utmSource=revie&utmMedium=whatsapp&${urlSpecial}`);
    }
    if(salesAmount !== 0){
      Util.getCurrency(isOKRates);
    }else{
      setCostMenssage(0);
      setRoi(0);
    }
  },[url, salesAmount, filter]);

  const handleOkUTM = (data) =>{
    if(data && data.totalPrice && data.totalPrice > 0){
        setSalesAmount(data.totalPrice);
        setSales(data.mediumCount[0].count);
    }else{
        setSalesAmount(0);
        setSales(0);
    }
}

  const handleOk = (data) => {
    setListCampaings(data);
    let pending = 0;
    let totalMessage = 0;
    if(data){
      data.map((item, key) =>{
        if("PENDING" === item.state || "DRAFT" === item.state){
          pending += 1; 
        } 

        if(item.campaignResult && item.campaignResult.total){
          totalMessage+= item.campaignResult.total;
        }
      });
      setSend(totalMessage);
      setTotalCampaings(data.length);
    }else{
      setTotalCampaings(0);
    }
    setPendingCampaings(pending);
  }

  const dataOk = (data) => {
    //setData(data);
    console.log("data",data);
    let read = 0;
    let delivered = 0;
    let send = 0;
    let toRead = 0;
    let error = 0;
    if(data && data.length > 0){
        data.map((item, key) =>{
            if(item.key === "read"){
                read += item.count; 
            } 
            if(item.key === "delivered" || item.key === "read" || item.key === "received" || item.key === "sent"){
                delivered += item.count; 
            }
            if(item.key === "delivered" || item.key === "received" || item.key === "sent"){
              toRead += item.count; 
            }
            if(item.key === "error"){
              error += item.count; 
            } 
            
            send += item.count; 
        });
        setRead(read);
        setDelivered(delivered);
        //setSend(send);
        setToRead(toRead);
        setError(error);
    }else{
        setRead(0);
        setDelivered(0);
        setSend(0);
        setToRead(0);
        setError(0);
    }
  }
  const dataOk24 = (data) => {
    //setData(data);
    let send = 0;
    if(data && data.length > 0){
        data.map((item, key) =>{
            send += item.count; 
        });
      }

    setTotal24(send);
  }
  const {data, refreshUrl, isLoad} = useStatistics({ urlInicial: "?"+urlSpecial, callback: dataOk})
  //const {data24, refreshUrl24, isLoad24} = useStatistics({ urlInicial: "?"+urlSpecial24, callback: dataOk24})
  
  const dataFail = (data) => {

  }
  const options = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };
  const getDateFormat = (dateString) =>{
    const event = new Date(dateString);
    return event.toLocaleDateString(window.paramRevie.language, options);
}

const getDateFormatHour = (dateString) =>{
    const event = new Date(dateString);
    return new Intl.DateTimeFormat(window.paramRevie.language, { dateStyle: 'full', timeStyle: 'medium' }).format(event);
}


  let message_send = delivered;
  let message_ready = read;
  const USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});
  let secondTotal ={
    count: PendingCampaings,
    textTotal: Util.getMessage("analicts_campaign_8")
  };
  let secondMessage ={
    count: read.toLocaleString(window.paramRevie.language),
    textTotal: Util.getMessage("analicts_campaign_9")
  };
  let secondSales ={
    count: USDollar.format(costMenssage),
    textTotal: Util.getMessage("analicts_campaign_10")
  };
  
  return (
    <div className="home">
      <Filter isIntervalShow={false} setFilter={updateFilter} filter={filter} fromText="fromNotifyDate"  toText="toNotifyDate"/>
      <div className='campaigns'>
      <div className='content-flex space-between-start mobile-scroll flex-direction-column-mobile'>
        <DashboardCards title={Util.getMessage("analicts_campaign_1")} second={secondTotal} count={totalCampaings} textTotal={Util.getMessage("analicts_campaign_1")} percentage={"0"}/>
        <DashboardCards title={Util.getMessage("analicts_campaign_2")} second={secondMessage} count={message_send.toLocaleString(window.paramRevie.language)} textTotal={Util.getMessage("analicts_campaign_2")} percentage={message_send ? Math.trunc((message_ready * 100) / message_send) : "0"}/>
        <DashboardCards title={Util.getMessage("analicts_campaign_3")} extra={sales + " "+Util.getMessage("analicts_campaign_4")} second={secondSales} count={USDollar.format(salesAmount)} textTotal={Util.getMessage("analicts_campaign_3")} percentage={roi} addPercentage="x"/>
        {status && status.properties ? (
          <DashboardCards title={Util.getMessage("analicts_campaign_44")} second={false} extraPercentage={Util.getMessage(status.properties.quality_rating)} titleTotal="" extratext={Util.getMessage("Customers/24hr")} textTotal={""} count={(total24 !== null && total24 > 0) ? (new Intl.NumberFormat(window.paramRevie.language).format((Util.LIMITWHATSAPP[status.properties.messaging_limit] - total24)) + " de "+new Intl.NumberFormat(window.paramRevie.language).format(Util.LIMITWHATSAPP[status.properties.messaging_limit])) : new Intl.NumberFormat(window.paramRevie.language).format(Util.LIMITWHATSAPP[status.properties.messaging_limit])} link="https://help.revie.ai/es/article/como-funcionan-los-limites-de-mensajes-diarios-en-whatsapp-y-como-incrementarlos-x8mobc/?bust=1732978063323" />
        ) : null}
      </div>
      </div>
      {/*<div className='content-flex space-between-start mobile-scroll flex-direction-column-mobile'>
        <DashboardCardsBottom title={Util.getMessage("analicts_campaign_5")} count={read.toLocaleString(window.paramRevie.language)}/>
        <DashboardCardsBottom title={Util.getMessage("analicts_campaign_6")} count={toRead.toLocaleString(window.paramRevie.language)}/>
        <DashboardCardsBottom title={Util.getMessage("analicts_campaign_7")} count={error.toLocaleString(window.paramRevie.language)}/>
  </div>*/}
      <div className='content-flex'>
        <div style={{width: "66%", marginTop: "20px"}}>
          <div className='content-table'>
            <div className='title'>{Util.getMessage("analicts_campaign_1")}</div>
          </div>
        {listCampaings && listCampaings.length > 0 ? (
          <>
          <div className='content-products cont-camp-prod'>
            {listCampaings.map((item, key) => (
              <div key={key} className='content-review content-product-item '>
                <div className='content-img-prod'>
                  <div>
                      {item.name}
                  </div>
                </div>
                <div className='content-flex flex-center gap16'>
                <div className={`cont-date ${item.state}`}>{
                    ("PENDING" === item.state || "DRAFT" === item.state) ? 
                    (<div>{Util.getMessage("date_table_2")}: {getDateFormatHour(item.notifiedAt)}</div>)
                    : (<div>{Util.getMessage("date_table_3")}: {getDateFormatHour(item.notifiedAt)}</div>)}
                </div>
                <div className='cont-stars cont-state'>{Util.getMessage("camp_"+item.state)}</div>
                <div className="cont-total">{item.campaignResult && item.campaignResult.total ? item.campaignResult.total : 0}</div>
                </div>
            </div>
            ))}
          </div>
        </>
        ): null}
        </div>
        <div style={{width: "32%", marginTop: "20px", marginLeft: "2%"}}>
          <div className='content-table'>
            <div className='title'>{Util.getMessage("analicts_campaign_2")}</div>
            {!isLoad && data && (
              <ChartRequest title={""} type="donut" isDataCheck={true} dataCheck={data} listColors={{"read":"#49A0FD","sent":"rgb(244, 165, 0)","read":"rgb(19, 153, 71)","undelivered":"rgb(253, 87, 84)", "queued": "rgb(119, 93, 208)", "failed": "rgb(255, 69, 96)", "delivered": "rgba(73, 160, 253, 0.85)", "error": "rgb(253, 87, 84)", "accepted": "rgb(237, 247, 239)"}} typeValue="int2" pre_label="campaign_" typeLabel="traductor" name={"Reviews"} delay={300} url={``} />
            )}
          </div>
        </div>
      </div>
    </div>
);
}

export default AnalyticsCampaigns;